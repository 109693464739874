.toast-buttons {
  display: flex;
}

.toast-buttons button {
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  height: 50px;
  margin: 0 10px;
  border: none;
  outline: none;
}

.select {
  display: flex;
  width: 30%;
  margin-top: 10px;
}

.position-select {
  background-color: inherit;
  color: #fff;
  width: 100%;
  height: 30px;
  font-size: 16px;
}

.success {
  background: #42ad7d;
}

.error {
  background: #fe6a6a;
}

.info {
  background-color: #5bc0de;
}

.warning {
  background-color: #f0ad4e;
}

.notification-container {
  font-size: 14px;
  position: fixed;
  z-index: 999999;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;

  @media only screen and (max-width: 414px) {
    right: 0;
    margin: 0 10px;
  }
}

.notification {
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 30px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  background-repeat: no-repeat;
  max-width: 417px;
  border-radius: 10px;
  min-width: 300px;

  @media only screen and (max-width: 500px) {
    min-width: unset;
    max-width: 240px;
    padding: 20px 15px;
  }

  @media only screen and (max-width: 375px) {
    position: static;
    min-width: unset;
  }
}

.notification:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  font-weight: 600;
}

.notification-message {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: 400;
  word-break: break-word;
}

.notification-image {
  float: left;
  margin-right: 15px;
  margin-top: 0.2rem;
}

.notification-image img {
  height: 25px;
  width: 25px;
}

.toast {
  border-radius: 10px;
  color: #fff;
}

.notification-close-icon {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-weight: 700;
  color: #fff;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  height: 8.75px;
  width: 8.75px;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
