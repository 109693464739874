.time-range-tab {
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
    font-weight: bold;
  }
}
