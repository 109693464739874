.footer-wrapper {
  position: absolute;
  bottom: 0;
  .footer-link {
    &-text {
      transition: opacity 0.2s ease-out;
      &:hover {
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
    }
  }

  @media only screen and (max-width: 834px) {
    margin-bottom: 8rem !important;
  }
}
