.apexcharts-custom-tooltip,
.apexcharts-tooltip {
  display: none !important;
}
.apexcharts-xcrosshairs {
  display: none;
}
.apexcharts-xaxistooltip {
  display: none;
}
