@keyframes tooltip-animatoin {
  from {
    opacity: 0;
    bottom: 0%;
  }
  to {
    opacity: 1;
    bottom: 3rem;
  }
}

.tooltip-wrapped {
  position: relative;

  .tooltip-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tooltip-title {
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
  & .tooltip {
    white-space: pre;
    background-color: #494949;
    padding: 5px 7px;
    position: absolute;
    // left: 50%;
    color: #fff;
    border-radius: 3px;
    font-size: 1.3rem;
    bottom: 3rem;
    // transform: translateX(-50%);
    display: none;
    z-index: 100;
    animation: tooltip-animatoin 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    & .tooltip {
      display: block;
    }
  }
}
