.loadable-content-wrapper {
  position: relative;
  width: 100%;
  height: fit-content;

  .middle-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }

  .pure-material-progress-circular {
    width: 60px;
    height: 60px;
  }
}
