.network-type-wrapper {
  background-color: #181422;
  min-height: 4rem;

  &.--hord {
    transition: all ease-in 0.2s;
    &:hover {
      background-color: #1e172e;
      transition: all ease-in 0.2s;
    }
  }
}
